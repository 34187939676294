<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: string;
    type?: "submit" | "button" | "link";
    layout?: "primary" | "secondaryLight" | "secondaryDark" | "bright";
    size?: "default" | "big";
    disabled?: boolean;
    target?: "_blank" | undefined;
    loading?: boolean;
    download?: boolean;
  }>(),
  {
    to: "",
    type: "link",
    layout: "primary",
    size: "default",
    disabled: false,
    target: undefined,
    loading: false,
    download: false,
  }
);

const emits = defineEmits(["click"]);

const onClick = (event: any) => {
  emits("click", event);
};

const isTouched = ref(false);

const touch = (val: boolean) => {
  isTouched.value = val;
};

const classDefinition = computed(() => {
  return {
    "border-1 inline-block rounded-md transition-all text-center select-none tap-highlight-color-transparent": true,

    "px-8 py-2": props.size === "default",
    "px-12 py-4 text-lg": props.size === "big",

    "cursor-pointer": !props.disabled,
    "opacity-30": props.disabled,

    "flex gap-x-4 items-center justify-center": props.loading,

    "border-maas-button-primary-border text-maas-button-primary-text bg-maas-button-primary-background": props.layout === "primary",
    "[@media(hover:hover)]:hover:bg-maas-button-primary-hover-background [@media(hover:hover)]:hover:border-maas-button-primary-hover-border [@media(hover:hover)]:hover:text-maas-button-primary-hover-text": !props.disabled && props.layout === "primary",
    "bg-maas-button-primary-hover-background border-maas-button-primary-hover-border text-maas-button-primary-hover-text": !props.disabled && props.layout === "primary" && isTouched.value,

    "border-maas-button-secondaryLight-border text-maas-button-secondaryLight-text bg-maas-button-secondaryLight-background": props.layout === "secondaryLight",
    "[@media(hover:hover)]:hover:border-maas-button-secondaryLight-hover-border [@media(hover:hover)]:hover:text-maas-button-secondaryLight-hover-text [@media(hover:hover)]:hover:bg-maas-button-secondaryLight-hover-background": !props.disabled && props.layout === "secondaryLight",
    "border-maas-button-secondaryLight-hover-border text-maas-button-secondaryLight-hover-text bg-maas-button-secondaryLight-hover-background": !props.disabled && props.layout === "secondaryLight" && isTouched.value,

    "border-maas-button-secondaryDark-border text-maas-button-secondaryDark-text bg-maas-button-secondaryDark-background": props.layout === "secondaryDark",
    "[@media(hover:hover)]:hover:border-maas-button-secondaryDark-hover-border [@media(hover:hover)]:hover:text-maas-button-secondaryDark-hover-text [@media(hover:hover)]:hover:bg-maas-button-secondaryDark-hover-background": !props.disabled && props.layout === "secondaryDark",
    "border-maas-button-secondaryDark-hover-border text-maas-button-secondaryDark-hover-text bg-maas-button-secondaryDark-hover-background": !props.disabled && props.layout === "secondaryDark" && isTouched.value,

    "border-maas-button-bright-border text-maas-button-bright-text bg-maas-button-bright-background": props.layout === "bright",
    "[@media(hover:hover)]:hover:border-maas-button-bright-hover-border [@media(hover:hover)]:hover:text-maas-button-bright-hover-text [@media(hover:hover)]:hover:bg-maas-button-bright-hover-background": !props.disabled && props.layout === "bright",
    "border-maas-button-bright-hover-border text-maas-button-bright-hover-text bg-maas-button-bright-hover-background": !props.disabled && props.layout === "bright" && isTouched.value,
  };
});
</script>

<template>
  <NuxtLink v-if="type === 'link'" :to="to" :disabled="props.disabled" :class="classDefinition" :target="target" @click="onClick" @touchstart.passive="touch(true)" @touchend.passive="touch(false)">
    <slot />
    <span v-if="loading" class="spinner w-4.5 h-4.5 before:border-current before:border-2"></span>
  </NuxtLink>

  <button v-else :type="type" :disabled="props.disabled" :class="classDefinition" @click="onClick" @touchstart.passive="touch(true)" @touchend.passive="touch(false)">
    <slot />
    <span v-if="loading" class="spinner w-4.5 h-4.5 before:border-current before:border-2"></span>
  </button>
</template>
